import { mapGetters } from "vuex"

export default {
  computed: {
    ...mapGetters(["quizzesDocumentsByQuizId"]),
    documents() {
      return this.quizzesDocumentsByQuizId(this.quiz?.id)
    },
    documentsByElement() { return (element) => {
      return  this.documents.filter( d => {

        return this.element?.attributes?.document_uuids &&
                 this.element?.attributes?.document_uuids.includes(d.uuid)
      })
    }
    },

  },
}
